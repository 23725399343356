import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AircraftTypesResponse } from '../models/aircraft-types-response';
import { ConfidentialitiesResponse } from '../models/confidentialities-reposnse';
import { ContributoryFactorsResponse } from '../models/contributory-factor-response';
import { FatigueCategoriesResponse } from '../models/fatigue-category-response';
import { AlertnessLevelsResponse } from '../models/alertness-levels-response';
import { SymptomsResponse } from '../models/symptoms-response';
import { SymptomTypesResponse } from '../models/symptom-types-response';
import { TimeZone } from '../models/time-zone';

@Injectable({
  providedIn: 'root'
})
export class FatigueReportService {
  private url: string = environment.apiUrl;

  constructor(private httpClient: HttpClient) {}

  getAircraftTypes(): Observable<AircraftTypesResponse> {
    return this.httpClient.get<AircraftTypesResponse>(
      `${this.url}/fatigueReportDictionaries/aircraftTypes`
    );
  }

  getConfidentialityLevels(): Observable<ConfidentialitiesResponse> {
    return this.httpClient.get<ConfidentialitiesResponse>(
      `${this.url}/fatigueReportDictionaries/confidentialityLevels`
    );
  }

  getContributoryFactors(): Observable<ContributoryFactorsResponse> {
    return this.httpClient.get<ContributoryFactorsResponse>(
      `${this.url}/fatigueReportDictionaries/contributoryFactors`
    );
  }

  getFatigueCategories(): Observable<FatigueCategoriesResponse> {
    return this.httpClient.get<FatigueCategoriesResponse>(
      `${this.url}/fatigueReportDictionaries/fatigueCategories`
    );
  }

  getAlertnessLevels(): Observable<AlertnessLevelsResponse> {
    return this.httpClient.get<AlertnessLevelsResponse>(
      `${this.url}/fatigueReportDictionaries/alertnessLevels`
    );
  }

  getSymptoms(): Observable<SymptomsResponse> {
    return this.httpClient.get<SymptomsResponse>(
      `${this.url}/fatigueReportDictionaries/symptoms`
    );
  }

  getSymptomTypes(): Observable<SymptomTypesResponse> {
    return this.httpClient.get<SymptomTypesResponse>(
      `${this.url}/fatigueReportDictionaries/symptomTypes`
    );
  }

  getTimeZoneData(): Observable<TimeZone[]> {
    return this.httpClient.get<TimeZone[]>('assets/time-zone-data.json');
  }
}
