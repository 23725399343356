import { HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import {
  MSAL_INTERCEPTOR_CONFIG,
  MsalBroadcastService,
  MsalGuardConfiguration,
  MsalInterceptor,
  MsalInterceptorConfiguration,
  MsalService
} from '@azure/msal-angular';
import {
  BrowserCacheLocation,
  IPublicClientApplication,
  InteractionType,
  LogLevel,
  PublicClientApplication
} from '@azure/msal-browser';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

export function loggerCallback(logLevel: LogLevel, message: string) {
  console.log(message);
}

export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
      clientId: environment.auth.clientId,
      authority: `https://login.microsoftonline.com/${environment.auth.tenantId}`,
      redirectUri: window.location.origin + '/auth',
      postLogoutRedirectUri: '/',
      clientCapabilities: ['CP1']
    },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage,
      storeAuthStateInCookie: false
    },
    system: {
      loggerOptions: {
        loggerCallback,
        logLevel: LogLevel.Info,
        piiLoggingEnabled: false
      }
    }
  });
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>();
  protectedResourceMap.set(environment.apiUrl, [ environment.apiScope ]);
  protectedResourceMap.set(environment.apiUrl + '/chatthreademail', [ environment.apiScope, 'mail.send' ]);
  protectedResourceMap.set('https://graph.microsoft.com/v1.0', [ 'mail.send' ]);

  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap
  };
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    authRequest: {
      scopes: [environment.apiScope, 'mail.send']
    }
  };
}

import { DOCUMENT, Location } from '@angular/common';
import { HTTP_REQUEST_METHODS } from 'ng-connection-service';
import { AppService } from './shared/services/app.service';

@Injectable({
  providedIn: 'root'
})
export class OfflineMsalInterceptor extends MsalInterceptor {

  constructor(
    @Inject(MSAL_INTERCEPTOR_CONFIG)
    msalInterceptorConfig: MsalInterceptorConfiguration,
    authService: MsalService,
    location: Location,
    msalBroadcastService: MsalBroadcastService,
    private appService: AppService,
    @Inject(DOCUMENT) document?: Document
  ) {
    super(
      msalInterceptorConfig,
      authService,
      location,
      msalBroadcastService,
      document
    );
  }

  override intercept(
    req: HttpRequest<HTTP_REQUEST_METHODS>,
    next: HttpHandler
  ): Observable<HttpEvent<HTTP_REQUEST_METHODS>> {
    if (!this.appService.isOnline()) {
      console.info('MSAL Interceptor - offline mode.');
      return next.handle(req);
    }
    console.info('MSAL Interceptor - online mode.');
    return super.intercept(req, next);
  }
}
