<mat-nav-list [style.marginTop.px]="isHandset ? 24 : 0">
  <a
    *ngIf="isReader || isInstructor || isAllReportsReader"
    mat-list-item
    routerLink="/admin"
    [routerLinkActive]="'is-active'"
    (click)="sidenav.close()">
    <mat-icon matListItemIcon>list</mat-icon>
    <span matListItemTitle>Reports list</span>
  </a>
  <a
    *ngIf="isReporter || isInstructor || isAllReportsReader"
    mat-list-item
    [class.is-active]="router.isActive('/report-form;r', true)"
    (click)="goToNewReport()">
    <mat-icon matListItemIcon>edit_note</mat-icon>
    <span matListItemTitle>New report</span>
  </a>
  <a
    *ngIf="isReporter || isInstructor || isAllReportsReader"
    mat-list-item
    routerLink="/my-reports"
    [routerLinkActive]="'is-active'"
    (click)="sidenav.close()">
    <mat-icon matListItemIcon>grade</mat-icon>
    <span matListItemTitle>My reports</span>
  </a>
  <a
    *ngIf="isReporter || isInstructor || isAllReportsReader"
    mat-list-item
    [class.is-active]="router.isActive('/report-form/saved-reports', true)"
    routerLink="/report-form/saved-reports"
    (click)="sidenav.close()">
    <mat-icon matListItemIcon>save</mat-icon>
    <span matListItemTitle>My drafts</span>
  </a>

  <a mat-list-item *ngIf="isHandset">
    <button
      mat-flat-button
      color="warn"
      [ngClass]="{ isHandset: isHandset }"
      (click)="logout()">
      <mat-icon
        aria-hidden="false"
        aria-label="Logout"
        fontIcon="logout"></mat-icon>
      <span>Logout</span>
    </button>
  </a>
</mat-nav-list>
